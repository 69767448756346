import axios from '@services/HTTP'

type userData = {
  first_name: string
  last_name: string
  date_of_birth: string
}
export const createPatient = async (userDetails: userData, vendor: string) => {
  type req = userData & {
    questionnaire: string
    vendor: string
  }
  type res = {
    patient: string
  }
  return axios.post<req, res>('/patient', {
    ...userDetails,
    vendor
  })
}

export function isValidJWT(token: string) {
  if (!token) return
  const payload = atob(token.split('.')[1])
  const decoded = JSON.parse(payload.toString())
  return Date.now() + 5000 < decoded.exp * 1000
}
