export const isStorageAvailable = () => {
  try {
    return !!window.localStorage
  } catch {
    return false
  }
}

const syncStorage = () => {
  window.parent.postMessage(
    {
      type: 'syncStorage',
      data: memoryStorage
    },
    '*'
  )
}

const memoryStorage: Record<string, string> = {}

export const safeStorage = {
  getItem: (key: string) => {
    try {
      return memoryStorage[key] ?? localStorage.getItem(key)
    } catch (e) {
      return null
    }
  },
  setItem: (key: string, value: string): void => {
    try {
      memoryStorage[key] = value
      syncStorage()
      localStorage.setItem(key, value)
    } catch {}
  },
  removeItem: (key: string): void => {
    try {
      memoryStorage[key] = null
      syncStorage()
      localStorage.removeItem(key)
    } catch {}
  }
}
