import axios from '@services/HTTP'
import type { CartItem } from 'store/checkout'

export const createCart = async (data: CartItem[]) => {
  const vendor = window.location.pathname.split('/')[2]

  const response = await axios.post<{ cart_hash: string }, { cart_hash: string }>('/cart', {
    vendor_hash: vendor,
    items: data
  })

  return response
}
