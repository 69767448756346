import axios from '@services/HTTP'
import type { CartItem } from 'store/checkout'

export const getCurrentCart = async () => {
  const response = await axios.get<{ items: CartItem[]; cart_hash: string }, { items: CartItem[]; cart_hash: string }>(
    '/customer/cart'
  )

  return response
}
