import axios from '@services/HTTP'
import type { CartItem } from 'store/checkout'

export const getCartByHash = async (id: string) => {
  const response = await axios.get<{ items: CartItem[]; cart_hash: string }, { items: CartItem[]; cart_hash: string }>(
    `/cart/${id}`
  )

  return response
}
