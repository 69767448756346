'use client'
import { useRouter, useSearchParams } from 'next/navigation'
import { create } from 'zustand'

interface Message {
  id?: string
  text: string
  role: 'user' | 'assistant'
  action?: string
  data?: any
}

interface DiscoveryStore {
  input: string
  setInput: (input: string) => void
  ID: string
  newID: () => void
  messages: Message[]
  addMessage: (message: Message) => void
  removeLastMessage: () => void
  removeAllMessages: () => void
}

export const useDiscoveryStore = create<DiscoveryStore>(set => ({
  input: typeof window === 'undefined' ? '' : new URLSearchParams(window.location.search).get('search') ?? '',
  setInput: input => set({ input }),
  ID: Math.random().toString(36).substring(7),
  newID: () =>
    set({
      ID: Math.random().toString(36).substring(7)
    }),
  messages: [],
  addMessage: message =>
    set(state => {
      message.id = message.id ?? Math.random().toString(36).substring(7)
      return {
        messages: [...state.messages, message]
      }
    }),
  removeLastMessage: () =>
    set(state => {
      const messages = [...state.messages]
      messages.pop()
      return {
        messages
      }
    }),
  removeAllMessages: () =>
    set({
      messages: []
    })
}))

export const useDiscoveryStoreActions = () => {
  const router = useRouter()
  const search = useSearchParams()
  const focused = search.has('discovery')

  const open = () => {
    const params = new URLSearchParams(search.toString())
    params.set('discovery', '')
    router.push(`?${params.toString()}`)
  }

  const close = () => {
    const params = new URLSearchParams(search.toString())
    params.delete('discovery', '')
    router.replace(`?${params.toString()}`)
  }

  return {
    isOpen: focused,
    open,
    close
  }
}
