import axios from '@services/HTTP'
import { useDiscoveryStore } from 'store/discovery'

export const discoverLog = async (message: string, data?: string) => {
  const log = {
    content: message,
    data,
    timestamp: Date.now(),
    threadID: useDiscoveryStore.getState().ID
  }

  return axios.post('/api/discovery/log', log, {
    baseURL: '',
    headers: {
      Authorization: null
    }
  })
}
