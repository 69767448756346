var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a26b2082f089f95e2f6fcc265ce1eafb4a943b0e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'
import { useCheckoutStore } from './store/checkout'
import { isStorageAvailable } from './utils/storage'
import { usePatientStore } from './store/patient'
import { useAuthState } from './store/auth'
import { queryClient } from './services/query'

Sentry.init({
  environment: process.env.NEXT_PUBLIC_APP_ENVIRONMENT,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: !!process.env.NEXT_PUBLIC_SENTRY_ENABLED,
  normalizeDepth: 6,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  beforeSend(event) {
    if (event.exception?.values?.some(e => e.type === 'ChunkLoadError')) {
      return null
    }
    if (event.exception?.values?.some(e => e.type === 'TypeError' && e.value === 'Load failed')) {
      return null
    }
    if (event.exception?.values?.some(e => e.type === 'TypeError' && e.value === 'Load failed')) {
      return null
    }

    event.user = queryClient.getQueryData(['user']) ?? null
    event.contexts = {
      ...event.contexts,
      additional_info: {
        storage_available: isStorageAvailable()
      },
      store: {
        auth: useAuthState.getState(),
        checkout: useCheckoutStore.getState(),
        patient: usePatientStore.getState()
      }
    }

    event.tags = {
      ...(event.tags ?? {}),
      storage_available: isStorageAvailable()
    }

    return event
  },
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'ui.click') {
      const text = !hint.event.target.childElementCount
        ? hint.event.target.innerText
        : hint.event.target.getAttribute('aria-label')
      if (text) {
        breadcrumb.message = text
      }
    }

    return breadcrumb
  }

  // replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  // integrations: [
  //   Sentry.replayIntegration({
  //     // Additional Replay configuration goes in here, for example:
  //     maskAllText: true,
  //     blockAllMedia: true,
  //   }),
  // ],
})
